.main-content {
  padding-top: 30px;
  padding-bottom: 30px;
}

.title {
  padding-bottom: 10px;
}

.stat-cards-updated {
  padding-bottom: 30px;
}

.stat-card {
  background: #fff;
  padding: 22px;
  margin-bottom: 20px;
  border-radius: 5px;
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
}
.stat-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}
.stat-card__icon-circle {
  height: 60px;
  width: 60px;
  border-radius: 60px;
  background: rgba(0, 123, 255, 0.2);
  display: flex;
  align-items: center;
  justify-content: center;
}
.stat-card__icon-circle i {
  font-size: 30px;
  color: #007bff;
}
.stat-card__icon.stat-card__icon--success .stat-card__icon-circle {
  background: rgba(40, 167, 69, 0.2);
}
.stat-card__icon.stat-card__icon--success .stat-card__icon-circle i {
  color: #28a745;
}
.stat-card__icon.stat-card__icon--danger .stat-card__icon-circle {
  background: rgba(220, 53, 69, 0.2);
}
.stat-card__icon.stat-card__icon--danger .stat-card__icon-circle i {
  color: #dc3545;
}
.stat-card__icon.stat-card__icon--warning .stat-card__icon-circle {
  background: rgba(255, 193, 7, 0.2);
}
.stat-card__icon.stat-card__icon--warning .stat-card__icon-circle i {
  color: #ffc107;
}
.stat-card__icon.stat-card__icon--primary .stat-card__icon-circle {
  background: rgba(0, 123, 255, 0.2);
}
.stat-card__icon.stat-card__icon--primary .stat-card__icon-circle i {
  color: #007bff;
}



.table-wrapper {
  padding-top: 20px;
}

.table {
  border-spacing: 0 4px;
  border-collapse: separate;
}

.table thead tr th,
.table thead tr td,
.table tbody tr th,
.table tbody tr td {
  vertical-align: middle;
  border: none;
}
.table thead tr th:nth-last-child(1),
.table thead tr td:nth-last-child(1),
.table tbody tr th:nth-last-child(1),
.table tbody tr td:nth-last-child(1) {
  text-align: center;
}


.table thead {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  border-radius: 5px;
}


.table tbody tr {
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  transition: all 0.2s;
  border-radius: 5px;
}
.table tbody tr td {
  background: #fff;
}
.table tbody tr td:nth-child(1) {
  border-radius: 5px 0 0 5px;
}
.table tbody tr td:nth-last-child(1) {
  border-radius: 0 5px 5px 0;
}

.table tbody tr:hover {
  transform: translateY(-2px);
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
}

.table .table-verified {
  background-color: rgba(40, 167, 69, 0.2) !important;
}

.table .table-not-verified {
  background-color: rgba(220, 53, 69, 0.2) !important;
}

.table .reward-progress {
  height: 10px;
}

.table .reward-progress-countdown {
  padding-top: 2px;
  white-space: nowrap;
}

.table .inline-text {
  white-space: nowrap;
}

.footer-wrapper {
  margin-top: 25px;
}
